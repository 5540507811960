@import '../styles/_green-variables';
@import '../styles/_mixins';
@import '../styles/_animations';

.site-navbar{
  user-select: none;
  .left-bar{
	position: fixed;
	height: 100vh;
	width: $navbar-size;
	border: 1px solid #E9E9E9;
	background-color: #FFFFFF;
	z-index: 1000000000;
	overflow: hidden;
	.content{
	  height: 100%;
	  position: relative;
	  .logo{
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		width: 28px;
		height: 57px;
		img, a{
		  width: 100%;
		  height: 100%;
		}
	  }
	  .open-close{
		position: absolute;
		top: 48%;
		transform: translateY(-50%);
		.burger{
		  width: 20px;
		  height: 17px;
		  position: relative;
		  transform: rotate(0deg);
		  transition: .5s ease-in-out;
		  cursor: pointer;
		  span{
			display: block;
			position: absolute;
			height: 2px;
			width: 20px;
			background: $dark;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .3s ease-in-out;
			&:nth-child(1) {
			  top: 0;
			}
			&:nth-child(2) {
			  top: 9px;
			}
			&:nth-child(3) {
			  top: 18px;
			}
			&:hover{
			  transition: all .3s cubic-bezier(.25,.46,.45,.94);
			}
		  }
		  &.open{
			span {
			  &:nth-child(1) {
				top: 9px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			  }
			  &:nth-child(2),
			  &:nth-child(4),
			  &:nth-child(5) {
				opacity: 0;
				left: -60px;
			  }
			  &:nth-child(3) {
				top: 9px;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-o-transform: rotate(-135deg);
				transform: rotate(-135deg);
			  }
			}
		  }
		}
	  }
	  .actual-page{
		position: absolute;
		bottom: 130px;
		left: 50%;
		width: 200px;
		font-family: $font-proxima-nova;
		transform: translateX(-50%) rotate(-90deg);
		color: #888888;
		font-size: 16px;
		font-weight: 600;
	  }
	}
  }
  .right-bar{
	position: fixed;
	height: 100vh;
	width: 0;
	background-color: #ffffff;
	z-index: 100000000;
	overflow: hidden;
	padding-left: 0;
	-webkit-transition: width .45s; /* For Safari 3.1 to 6.0 */
	transition: width .45s;
	.content{
	  position: relative;
	  height: 100vh;
	  width: 100vw;
	  .image{
		position: absolute;
		right: 0;
		top:0;
		bottom: 0;
		height: 100vh;
		width: 30%;
		min-width: 384px;
		overflow: hidden;
		.nav-bar-image{
		  object-fit: cover;
		  height: 100%;
		  width: 100%;
		  animation-delay: 1s;
		  animation: 1s ease-in-out slideOut;
		}
	  }
	  .list{
		position: absolute;
		top: 50%;
		left:40px;
		transform: translateY(-50%);
		width: 70%;
		animation: 1s ease-in-out contentFadeOut;
		padding-left: 85px;
		.nav-wrapper{
		  ul{
			list-style-type: none;
			li{
			  text-decoration: none;
			  padding-bottom: 37px;
			  position: relative;
			  a{
				text-decoration: none;
				font-family: $font-proxima-nova;
				font-size: 35px;
				font-weight: 500;
				line-height: 42px;
				color: #252525;
				position: relative;
				&.active{
				  color: $green;
					font-weight: 500;
					padding-bottom: 2px;
				  border-bottom: 3px solid #E9E9E9;
				}
				&:after{
				  position: absolute;
				  content: '';
				  height: 3px;
				  width: 100%;
				  bottom:-5px;
				  left: 0;
				  background-color: #252525;
				  -webkit-transform: scaleX(0);
				  transform: scaleX(0);
				  -webkit-transform-origin: 100% 50%;
				  transform-origin: 100% 50%;
				}
				&:not(.active){
				  &:hover{
					&:after{
					  will-change: transform;
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					  -webkit-transform-origin: 0 50%;
					  transform-origin: 0 50%;
					  transition: transform .35s,-webkit-transform .35s;
					  transition-timing-function: cubic-bezier(.25,.46,.45,.94);

					}
				  }
				}
			  }

			  &:hover{
				&:after{
				  cursor: pointer;
				}
			  }
			}
		  }
		}
	  }
	  .icon{
		position: absolute;
		top: 56px;
		right: 35%;
		content: '';
		background: url("../assets/images/logo_green_symbol.png") no-repeat center center;
		background-size: cover;
		height: 92px;
		width: 62px;
	  }
	}
  }
  &.open{
	.right-bar{
	  width: 100%;
	  .content{
		.image{
		  img{
			animation-delay: 1s;
			animation: 1s ease-in-out contentFadeInRight;
		  }
		}
		.list{
		  animation-delay: 1s;
		  animation: 1s ease-in-out contentFadeInTop;
		}
		.icon{
		  animation-delay: 1s;
		  animation: 1s ease contentFadeInBottom;
		}
	  }
	}
	.left-bar{
	  .content{
		.open-close{
		  .burguer{
			span{
			  &:nth-last-child(3){
				opacity: 0;
				transform: rotate(0deg) scale(0.2, 0.2);
			  }
			  &:nth-last-child(2)
			  {
				transform: rotate(-45deg) translate(0, -1px);
			  }
			}
		  }
		}
	  }
	}
  }
}


@media (min-width: $md-size) {
  .site-navbar {
	.left-bar {
	  .content {
		.open-close{
		  left:50%;
		  transform: translateX(-50%);
		}
	  }
	}
  }
}

@media (max-width: $md-size) {
  .site-navbar{
	padding-bottom: $navbar-size;
	.left-bar{
	  height: $navbar-size;
	  width: 100vw;
	  .content{
		.logo{
		  top: 27%;
		  transform: translateY(-50%);
		  left: 30px;
		  img{
			transform: rotate(90deg);
		  }
		}
		.open-close{
		  right: 40px;
		  width: 20px;
		}
		.actual-page{
		  width: 200px;
		  height: 16px;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%,-50%) rotate(0deg);
		  text-align: center;
		}
	  }
	}
	.right-bar{
		padding: 0;
	  .content{
		  .list .nav-wrapper{
			  ul li
			  {
				padding-bottom: 27px;
				  a{
					  font-size: 25px;
					  line-height: 28px;
					  &:active{
						border-bottom: 2px solid #E9E9E9;
					  }
				  }

			  }
		  }
		.icon{
		  display: none;
		}
	  }

	}
  }
}

@media (max-width: $sm-size) {
  .site-navbar{
	.left-bar{
	  .content{
		.actual-page{
		 display: none;
		}
	  }
	}
	.right-bar{
		padding: 0;
		.content{
			.list{
				width: 100%;
				display: flex;
				justify-content: center;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
		}
	}
  }
}
