@import "../../styles/_green-variables";

@media (min-width: $md-size){
  .not-implemented-wrapper {
    height: calc(100vh -  142px);
    position: relative;
    .not-implemented-content{
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      .title{
        font-size: 50px;
        line-height: 63px;
        text-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
        font-weight: bold;
      }
      .code{
        color: #E9E9E9;
        font-size: 42px;
        font-weight: 800;
        line-height: 65px;
        margin-bottom: 30px;
      }
    }
    button{
      margin-top: 30px;
    }
  }
}
@media (max-width: $md-size){
  .not-implemented-wrapper {
    height: calc(100vh -  200px);
    position: relative;
    .not-implemented-content{
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      padding: 0 16px;
      .title{
        font-size: 30px;
        line-height: 33px;
        text-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
        font-weight: bold;
      }
      .code{
        color: #E9E9E9;
        font-size: 30px;
        font-weight: 800;
        line-height: 35px;
        margin-bottom: 30px;
      }
    }
  }
}
