@import '../styles/_green-variables';
@import '../styles/_mixins';
@import '../styles/_animations';

.footer-wrapper {
  background-color: $dark;
  padding-bottom: 10px;
  padding-left: $navbar-size;

  //width adjustment due left nav bar that causes overflow
  .footer-0-overflow {
    width: calc(100% - 63px);
  }

  .list {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 23.5px 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    position: relative;

    &:first-child {
      border: none;
    }

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.5);
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;

      &.active {
        font-weight: bold;
        color: $white;
      }
    }
  }
}

@media (min-width: $md-size) {
  .footer-wrapper {
    padding-left: $navbar-size;

    .list {
      padding: 23.5px 0;

      a {
        padding-right: 32px;
      }

      .right {
        position: absolute;
        right: 0;
        padding: 0;
      }
    }
  }
}

@media (max-width: $md-size) {
  .footer-wrapper {
    padding-left: 0;

    .list {
      display: grid;

      &:first-child {
        padding-top: 45px;
      }

      a {
        width: 100%;
        text-align: center;
        &:not(:last-child) {
          padding-bottom: 25px;
        }
      }

      .right {
        text-align: center;

        &:last-child {
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          padding-top: 20px;
        }

        &.border {
          padding-top: 25px;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
