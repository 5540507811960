@import '../../styles/green-variables';
@import '../../styles/_mixins';

.wrapper-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 1;
}

.loading {
  width: 100px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -50px;
  text-align: center;
}

.loading .circle {
  width: 15px;
  height: 15px;
  background-color: $green;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  animation-name: loaderAnim;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.loading .circle-2 {
  animation-delay: 0.25s;
}

.loading .circle-3 {
  animation-delay: 0.5s;
}

.loading .circle-4 {
  animation-delay: 0.75s;
}

@keyframes loaderAnim {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
